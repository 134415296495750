#rating {
    width: 100%;
    padding-bottom: 5%;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
}

#ratingHeading {
    padding-top: 5vw;
    text-align: center;
    text-shadow: 2px 2px 2px black;
    font-size: 5vw;
    color: #ee609c;
}

#cardContainer {
    display: grid;
    grid-template-columns: auto;
}

#card {
    transition: 200ms;
    position: relative;
}

.cardContent {
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.womanIcon, .manIcon {
    font-size: 3vw;
    background-color: pink;
    border: 1px solid black;
    border-radius: 100px;
}

.userName {
    display: inline;
    font-size: 18px;
}

.userText {
    margin-top: 20px;
    font-size: 18px;
}

.cardHeadingContainer {
    border-bottom: 1px solid black;
}

.starsContainer {
    margin-top: 20px;
}

.ratingStar {
    color: yellow;
    text-shadow: 2px 2px 2px black;
    font-size: 2vw;
}

.starsCountText {
    font-size: 1.5vw;
    margin-left: 15px;
    font-weight: bold;
}

.ratingDate {
    position: relative;
    top: 0;
    font-weight: bold;
    float: right;
}

@media only screen and (max-width: 800px) {
    .ratingStar {
        font-size: 3vw;
    }
    .starsCountText {
        font-size: 2.5vw;
    }
}

@media only screen and (max-width: 800px) {
    .starsCountText {
        font-size: 3.5vw;
    }
}