#services {
    background-color: rgb(19, 150, 162);
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    width: 100%;
}

#servicesHeading {
    padding-top: 5vw;
    padding-bottom: 2vw;
    text-align: center;
    text-shadow: 2px 2px 2px black;
    font-size: 5vw;
    color: #ee609c;
}

.serviceGridContainer {
    display: grid;
    grid-template-columns: auto auto;
}

.servicePhotoContainer {
    grid-row-start: 1;
    grid-row-end: 5;
    width: 40%;
    margin: 50px;
}

.service {
    word-wrap: break-word;
    width: 90%;
    margin-bottom: 3vw;
}

.servicePhoto {
    width: 25vw;
    height: auto;
    position: relative;
    top: 0;
    border-radius: 10px;
    border: 2px solid white;
    box-shadow: 2px 2px 10px black;
}

.serviceH2 {
    color: #ee609c;
    text-shadow: 1px 1px 2px black;
}

@media only screen and (max-width: 850px) {
   .serviceGridContainer {
        grid-template-columns: auto;
    }

    .servicePhotoContainer {
        text-align: center;
    }

    .service {
        margin-left: 5%;
        margin-top: 5%;
    }

    .servicePhotoContainer {
        width: 100%;
        margin: 0;
    }
   
}

@media only screen and (max-width: 630px) {
    #servicesHeading {
        padding-top: 10vw;
    }
}

/* * {
    border: 1px solid red;
} */