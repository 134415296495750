#destinations {
    opacity: 0.99;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    border-bottom: 2px solid white;
    display: grid;
    grid-template-columns: 50% 50%;
}

.destination {
    margin: 20px;
}

.destinationName {
    text-align: center;
    color: #ee609c;
    text-shadow: 1px 1px 2px black;
    margin-top: 10px;
}

.destinationMapContainer {
    text-align: center;
}

.destinationMap {
    border: 1px solid black;
    margin: 20px;
}

.cubaDestinationText {
    text-align: center;
}

.havanaDestinationsList {
    margin: 2vw 10vw;
    font-size: 20px;
}

@media only screen and (max-width: 900px) {
    #destinations {
        grid-template-columns: 100%;
    }

    .cubaMap {
        grid-row: 2;
    }
}