:root {
  --footerColor: wheat;
}

#footerContainer {
  background-color: rgb(34, 32, 32);
  border-top: 1px solid var(--footerColor);
}

#footerHeading {
  text-align: center;
  font-size: 3vw;
  padding: 5vw;
  color: var(--footerColor);
  text-shadow: 2px 2px 2px black;
}

.contactContainer {
  color: var(--footerColor);
  margin: 0 10%;
  width: 70%;
}

.mapContainer,
.contactContainer,
.socialMediaList > li {
  display: inline-block;
}

.contactHeading,
.findHeading {
  width: fit-content;
  border-image: linear-gradient(to left, rgb(34, 32, 32), var(--footerColor)) 10;
  border-width: 1px;
  font-size: 1.5vw;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 300px;
}

.creatorEmail {
  margin: 0;
  color: white;
  text-decoration: none;
}

.contactList,
.socialMediaList {
  list-style-type: none;
  margin-top: 10px;
}

.socialMediaList {
  font-size: 2vw;
}

.contactList > li {
  margin-top: 10px;
}

.mapContainer {
  width: 100%;
  color: var(--footerColor);
}

.map {
  width: 90%;
  margin-top: 10px;
  box-shadow: 10px 10px 10px black;
  border: 2px solid black;
  outline: 2px solid var(--footerColor);
}

.mapIframe {
  border-radius: 10px;
  border: 3px solid black;
  outline: 1px solid var(--footerColor);
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.socialMediaListContainer {
  width: 100%;
}

.socialMediaIcons {
  border-bottom: 1px solid var(--footerColor);
  width: 90%;
  text-align: center;
  margin: auto;
  margin-top: 2%;
  transform: translateX(-50%, -50%);
}

.signature {
  margin-top: 1%;
  padding-bottom: 2%;
  width: 100%;
  text-align: center;
  font-size: 0.6vw;
  color: var(--footerColor);
}

.whatsappIconContactUs {
  color: rgb(31, 247, 31);
}

.emailIconContactUs {
  color: gold;
}
.phoneIconContactUs {
  color: grey;
}
.locationIconContactUs {
  color: red;
}

.whatsappIcon,
.youtubeIcon,
.instagramIcon {
  transition: 200ms;
  margin: 0 15px;
}

.instagramIcon {
  color: #ff00c8;
}

.whatsappIcon {
  color: rgb(31, 247, 31);
}

.youtubeIcon {
  color: red;
}

.whatsappIcon:hover,
.youtubeIcon:hover,
.instagramIcon:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.locationLink:hover {
  color: red;
}

.whatsappLink:hover {
  color: rgb(31, 247, 31);
}

.emailLink:hover {
  color: gold;
}

.phoneLink:hover {
  color: grey;
}

.footerContactLink {
  font-size: 1vw;
}

.footerContactLink {
  text-decoration: none;
  margin: 20px;
  color: white;
}

.QRcode {
  margin-top: 2%;
  border-radius: 10px;
  box-shadow: 5px 5px 5px black;
}

@media only screen and (max-width: 950px) {
  .footerContactLink,
  .findHeading,
  .contactHeading {
    font-size: 10px;
  }

  .gridContainer {
    grid-template-columns: auto;
  }

  .signature {
    font-size: 1.5vw;
  }

  .mapContainer {
    max-width: 100%;
    margin-top: 50px;
  }

  .contactContainer,
  .mapContainer {
    margin: 0 10%;
  }

  .mapIframe {
    margin: 0 10%;
    width: 100%;
  }

  .mapContainer {
    margin-top: 5%;
    width: 85%;
  }

  .mapIframe {
    width: 100%;
    margin: 0 1%;
  }

  .gridItem {
    width: 90%;
  }
  .whatsappIcon,
  .youtubeIcon,
  .instagramIcon {
    font-size: 30px;
  }
}
