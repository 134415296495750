.navbar {
    position: absolute;
    top: 100px;
    border-radius: 20px;
    right: 5%;
    width: fit-content;
    transition: 500ms;
    z-index: 99;
}

.active {
    padding: 20px;
    transition: padding 500ms;
    left: 0;
    border-radius: 0 0 10px 10px;
    position: fixed;
    top: 0;
    width: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    box-shadow: 2px 2px 10px black;
}

.navbarList {
    text-align: center;
    font-size: 20px;
    color: white;
}

.navbarList > li {
    display: inline;
    transition: 200ms;
    margin: 5px;
    color: black;
}

.navbarItem {
    text-decoration: none;
    background-color: #1f1f2e;
    color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px black;
    border: 1px solid black;
    padding: 7px;
    transition: 200ms;
}

.navbarItem:hover {
    background-color: #00f2fe;
    color: black;
}

@media only screen and (max-width: 1050px) {
    .navbarItem {
        padding: 5px;
    }
    .navbar {
        right: 0;
    }
}

@media only screen and (max-width: 830px) {
    .navbar {
        top: 200px;
    }
    .active {
        top: 0;
        left: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .navbarList {
        font-size: 3.3vw;
    }
}