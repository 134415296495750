#titleContainer {
  background-image: radial-gradient(
    circle 248px at center,
    #16d9e3 0%,
    #30c7ec 47%,
    #46aef7 100%
  );
  width: 100%;
  height: 190px;
}

.languageButtons {
  position: absolute;
  top: 0;
  right: 0;
}

.socialMediaContainer {
  position: absolute;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 2px 2px 5px black;
}

.whatsapp,
.instagram {
  font-size: 30px;
  float: left;
  margin: 10px;
  transition: 200ms;
}

.whatsapp {
  color: rgb(31, 247, 31);
}

.instagram {
  color: #ff00c8;
}

.whatsapp:hover,
.instagram:hover {
  transform: scale(1.2);
}

.languageButtons > :hover {
  cursor: pointer;
  transform: scale(1.2);
}

.slovakFlag,
.englishFlag,
.frenchFlag,
.russiaFlag,
.spainFlag {
  border: 1px solid black;
  box-shadow: 2px 2px 3px black;
  margin: 5px;
  transition: 200ms;
  border-radius: 3px;
}

.languageButtons > :active {
  transition: 10ms;
  transform: scale(1);
}

.logoContainer {
  width: fit-content;
  margin-left: 15%;
}

.logo {
  width: 275px;
  height: 132px;
  margin-top: 5%;
}

.siteHeading {
  text-align: center;
  font-size: 28px;
  color: #ee609c;
  -webkit-text-stroke: 1px black;
}

@media only screen and (max-width: 900px) {
  .logo {
    width: 200px;
    height: 100px;
    margin-top: 20%;
  }
  .siteHeading {
    font-size: 20px;
  }
}

@media only screen and (max-width: 830px) {
  #titleContainer {
    height: 250px;
  }
}
