html, body {
  height: 100%;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

p {
  font-size: 18px;
}