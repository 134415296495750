#slideshowContainer {
    width: 100%;
    border-top: 2px solid white;
    position: relative;
}

.backgroundPhoto {
    max-width: 100%;
    height: auto;
}

.slideshowText {
    text-align: center;
    text-shadow: 2px 5px 10px black;
    color: #00f2fe;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5.5vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    animation: fading 1s forwards;
}

@keyframes fading {
    from {
        top: 20%;
        opacity: 0;
        filter: blur(50px);
    }
    to {
        top: 70%;
        opacity: 1;
        filter: blur(0);
    }
}