#sectionOneContainer {
    background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);
    border-bottom: 2px solid white;
}

#sectionHeading {
    padding-top: 5vw;
    padding-bottom: 2vw;
    text-align: center;
    text-shadow: 2px 2px 2px black;
    font-size: 5vw;
    color: #00f2fe;
}

.previewContainer {
    width: 100%;
}

.previewPhoto {
    max-width: 40%;
    box-shadow: 5px 5px 20px black;
    border: 2px solid black;
    border-radius: 10px;
    height: auto;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.previewVideo {
    max-width: 20%;
    box-shadow: 5px 5px 20px black;
    border: 2px solid black;
    border-radius: 10px;
    height: auto;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.galleryContainer {
    display: grid;
}

.photosContainer {
    margin-top: 20px;
    display: inline;
    text-align: center;
    margin-bottom: 5vw;
    padding: 10px;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    border: 1px solid black;
}

.photo {
    max-width: 5%;
    height: auto;
    margin: auto;
    border: 1px solid white;
    transition: 200ms;
}

.video {
    max-width: 1.86%;
    border: 1px solid white;
    transition: 200ms; 
}

.photo:hover, .video:hover {
    cursor: pointer;
    filter: brightness(1.4);
}

@media only screen and (max-width: 1100px) {
    .previewPhoto {
        max-width: 70%;
    }
    .photo {
        max-width: 9%;
    }
    .video {
        max-width: 3.35%;
    }
}


@media only screen and (max-width: 630px) {
    #sectionHeading {
        padding-top: 10vw;
    }
}